<template>
  <PageList
    :value="pages"
    @fetch="fetchPages"
  />
</template>

<script>
import PageList from 'common/components/pageList/PageList';
import handleError from 'common/helpers/handleError';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'PagesPage',
  components: { PageList },
  computed: {
    ...mapState('dashboard', ['pages']),
  },
  created() {
    this.fetch();
  },
  methods: {
    ...mapActions('dashboard', ['fetchPages']),
    async fetch() {
      try {
        this.$q.loading.show();
        await this.fetchPages();
      } catch (error) {
        handleError(error);
      } finally {
        this.$q.loading.hide();
      }
    },
  },
};
</script>
